<template>
  <v-container fluid class="pa-0">
    <v-window v-model="window" touchless>

      <!-- Choose phone or email -->
      <v-window-item :value="0">
        <v-row dense>
          <v-col cols="12" class="title text-center">
            Which would you like to verify?
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="text-center">
            <v-btn color="color3 color3Text--text" @click.stop="selectType('Email')">Email</v-btn>
          </v-col>
        </v-row>
        <!-- BEGIN OR LINE -->
        <v-row class="my-3">
          <v-col class="text-center" cols="12" md="10" offset-md="1" sm="8" offset-sm="2" >
            <h2 class="lined"><span>Or</span></h2>
          </v-col>
        </v-row>
        <!-- END OR LINE -->
        <v-row dense>
          <v-col cols="12" class="text-center">
            <v-btn color="color3 color3Text--text" @click.stop="selectType('Mobile Phone')">Mobile Phone</v-btn>
          </v-col>
        </v-row>
      </v-window-item>

      <!-- Enter phone or email -->
      <v-window-item :value="1">
        <v-row dense>
          <v-col cols="12" class="subtitle-1">
            {{contactType === 'Email' ? 'Enter the email address you would like to verify' : 'Enter the phone nummber you would like to verify (must be sms ready)'}} and we will send a verification code.
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              key="tb_email"
              v-if="contactType === 'Email'"
              v-model="email"
              label="Email"
              color="color3"
              hint="Enter the email address you would like to verify"
              persistent-hint
            ></v-text-field>
            <v-text-field
              v-else
              key="tb_phone"
              v-model="phone"
              label="Mobile Phone"
              color="color3"
              hint="Enter the phone nummber you would like to verify (must be sms ready)"
              persistent-hint
              v-mask="'(###) ###-####'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
              color="success white--text"
              :disabled="!validContact"
              :loading="loading"
              @click.stop="sendCode"
            >Send Code</v-btn>
          </v-col>
        </v-row>
      </v-window-item>

      <!-- Enter verficiation code -->
      <v-window-item :value="2">
        <v-row dense>
          <v-col cols="12">
            <div class="title">Enter the verficiation code</div>
            <div v-if="!skip">We just sent {{contactType === 'Mobile Phone' ? 'a text message' : 'an email'}} with a fresh verification code</div>
            <div class="caption">Note: only the last code issued will be valid and codes are only good for 10 minutes</div>
            <v-text-field
              label="Verification Code"
              v-mask="'######'"
              v-model="vCode"
              color="color3"
            ></v-text-field>
            <v-alert type="error" :value="!!codeError" text prominent>
              {{codeError}}
            </v-alert>
            <div class="text-center pb-3">
              <v-btn
                color="color3 color3Text--text"
                :disabled="!validCode"
                @click.stop="verifyCode"
                :loading="loading"
              >Go</v-btn>
            </div>
            <div class="caption text-center">Didn't receive a code? <span class="clickable color3--text" @click.stop="reset">Try again.</span></div>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <v-row dense>
      <v-col cols="12">
        <v-fab-transition>
          <v-btn
            v-if="window > 0"
            color="color3Text color3--text"
            small fab
            @click.stop="reset"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="color3"
            text x-small
            @click.stop="skipToCode"
            v-if="window === 0"
          >I already have a code</v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import ValidUSPhone from '@/helpers/ValidUSPhone'
import ValidEmail from '@/helpers/ValidEmail'

export default {
  directives: { mask },
  props: ['addType', 'contactIn'],
  data () {
    return {
      window: 0,
      contactType: null,
      email: null,
      phone: null,
      loading: false,
      vCode: null,
      codeError: null,
      skip: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    validContact () {
      return this.contactType && this.contactType === 'Email' ? (this.email && ValidEmail(this.email)) : (this.phone && ValidUSPhone(this.phone))
    },
    validCode () {
      return this.vCode && this.vCode.length === 6
    },
    dto () {
      return {
        contactType: this.contactType,
        contact: this.contactType === 'Email' ? this.email : this.phone.replace(/\D/g, '')
      }
    }
  },
  methods: {
    selectType (t) {
      this.contactType = t
      this.window = 1
    },
    reset () {
      this.window = 0
      this.contactType = null
      this.phone = null
      this.email = null
      this.vCode = null
      this.codeError = null
      this.skip = false
    },
    skipToCode () {
      this.skip = true
      this.window = 2
    },
    sendCode () {
      if (this.validContact) {
        this.loading = true
        this.$VBL.user.contact.add(this.dto)
          .then(r => {
            this.window = 2
          })
          .catch(e => console.log(e))
          .finally(() => { this.loading = false })
      }
    },
    verifyCode () {
      if (this.validCode) {
        this.loading = true
        this.$VBL.user.contact.confirm(this.vCode)
          .then(r => {
            this.$emit('verified')
            this.reset()
          })
          .catch(e => {
            console.log(e)
            this.codeError = e.response.data.Message
          })
          .finally(() => { this.loading = false })
      }
    }
  },
  mounted () {
    if (this.addType) {
      this.selectType(this.addType)
      if (this.contactIn) {
        console.log('here')
        if (this.addType === 'Email') {
          this.email = this.contactIn
        } else {
          this.phone = this.contactIn
        }
      }
    }
  }
}
</script>
<style scoped>
  h2.lined {
    border-bottom: 1px solid grey;
    line-height: 0.1em;
  }
  h2.lined span {
    padding: 0 10px;
    background: #fff
  }
</style>
