<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Verify your account</v-toolbar-title>
          </v-toolbar>
          <v-window v-model="window">
            <v-window-item :key="0">
              <v-card-title>
                Choose a verification method
              </v-card-title>
              <v-card-text>
                {{user.firstName}}, you have never verified your account, you must have a verified email address or mobile number on file.
                <contact-add
                  ref="addContact"
                  @verified="onVerifed"
                ></contact-add>
              </v-card-text>
            </v-window-item>
            <v-window-item :key="1">
              <v-card-title v-if="selectedPlayer">
                Claim any profiles
              </v-card-title>
              <v-card-text v-if="loading">
                <div class="text-center">
                    <v-progress-circular indeterminate color="color3" v-if="loading"></v-progress-circular>
                </div>
              </v-card-text>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactAdd from '@/components/Account/ContactAdd'

export default {
  data () {
    return {
      quickEdit: false,
      loading: false,
      selectedPlayer: null,
      memberships: null,
      window: null,
      videoId: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    userStatus () {
      // return -1
      if (!this.user) return 0 // 'No User'
      if (!this.user.hasVerifiedContact) return -1 // 'Need Verification'
      return 13
    }
  },
  methods: {
    onVerifed () {
      this.$router.push({ name: 'me' })
    }
  },
  components: {
    ContactAdd
  }
}
</script>
